import React, { useState, useEffect, useRef } from "react";
import "./Chat.css";
import LoadingIndicator from "./LoadingIndicator";
import ConfirmationModal from "./ConfirmationModal";
import { MdReplay } from "react-icons/md";
import { BsCheckLg } from "react-icons/bs";

function App() {
  const MAX_MESSAGES_PER_OPTION = 30;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOption, setModalOption] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showRequestCompletedOption, setShowRequestCompletedOption] =
    useState(false);

  const [chatHistories, setChatHistories] = useState(() => {
    const storedData = localStorage.getItem("chat_app_data");
    return storedData
      ? JSON.parse(storedData).chat_histories
      : {
          Customs: [],
          Invoicing: [],
          "Email Generation": [],
          "Load Quote": [],
        };
  });

  const [input, setInput] = useState("");
  const chatBodyRef = useRef(null);
  // Handle option selection
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    if (chatHistories[option].length === 0) {
      addSystemMessage(`How can I assist you with ${option}?`, option);
    }
  };

  // Function to add a system message
  const addSystemMessage = (text, option = selectedOption) => {
    const newMessage = {
      id: generateUniqueId(),
      text,
      isUser: false,
    };
    setChatHistories((prevHistories) => {
      const updatedOptionMessages = [
        ...prevHistories[option],
        newMessage,
      ].slice(-MAX_MESSAGES_PER_OPTION);
      return {
        ...prevHistories,
        [option]: updatedOptionMessages,
      };
    });
  };

  const openModal = (option) => {
    setModalOption(option);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalOption(null);
  };

  const apiEndpoints = {
    Customs: "/api/customs",
    Invoicing: "/api/invoicing",
    "Email Generation": "/api/email-generation",
    "Load Quote": "/api/load-quote",
  };

  // Initialize session_id using useEffect
  useEffect(() => {
    // Check if session_id exists in sessionStorage
    let sessionId = sessionStorage.getItem("session_id");
    if (!sessionId) {
      // Generate a new session_id and store it
      sessionId = generateSessionId();
      sessionStorage.setItem("session_id", sessionId);
    }
  }, []);

  useEffect(() => {
    const storedData = localStorage.getItem("chat_app_data");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setSelectedOption(parsedData.selectedOption || null);
    }
  }, []);

  useEffect(() => {
    const dataToStore = {
      selectedOption,
      chat_histories: chatHistories,
    };
    localStorage.setItem("chat_app_data", JSON.stringify(dataToStore));
  }, [chatHistories, selectedOption]);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [chatHistories, isLoading]);

  function generateSessionId() {
    return crypto.randomUUID();
  }

  // Function to generate a unique message ID
  function generateUniqueId() {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  }

  // Function to handle sending messages and waiting for API response
  const handleSend = async () => {
    const textToSend = input.trim();
    if (!selectedOption) {
      alert("Please select an option before sending messages.");
      return;
    }
    if (textToSend === "") return; // Do not send empty messages

    setShowRequestCompletedOption(false);

    // Create user message
    const userMessage = {
      id: generateUniqueId(),
      text: textToSend,
      isUser: true,
    };

    // Append user message with pruning
    setChatHistories((prevHistories) => {
      const updatedMessages = [
        ...prevHistories[selectedOption],
        userMessage,
      ].slice(-MAX_MESSAGES_PER_OPTION);
      return {
        ...prevHistories,
        [selectedOption]: updatedMessages,
      };
    });

    // Clear input field
    setInput("");
    setIsLoading(true);

    // Call the API
    try {
      const response = await callAzureFunction(textToSend, selectedOption);
      const answer = response.message; // Assuming API returns { message: "..." }

      // Create AI message
      const aiMessage = {
        id: generateUniqueId(),
        text: answer,
        isUser: false,
      };

      // Append AI message with pruning
      setChatHistories((prevHistories) => {
        const updatedMessages = [
          ...prevHistories[selectedOption],
          aiMessage,
        ].slice(-MAX_MESSAGES_PER_OPTION);
        return {
          ...prevHistories,
          [selectedOption]: updatedMessages,
        };
      });

      setShowRequestCompletedOption(true);
    } catch (err) {
      console.error("Error fetching response from the API:", err);
      const errorMessage = {
        id: generateUniqueId(),
        text: "Error fetching response from the system.",
        isUser: false,
      };
      setChatHistories((prevHistories) => {
        const updatedMessages = [
          ...prevHistories[selectedOption],
          errorMessage,
        ].slice(-MAX_MESSAGES_PER_OPTION);
        return {
          ...prevHistories,
          [selectedOption]: updatedMessages,
        };
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Function to call the Azure Function
  const callAzureFunction = async (inputText, option) => {
    const apiUrl = apiEndpoints[option] || "/api/communications-manager"; // Replace with your actual Azure Function URL

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        question: inputText, // Send the user's input as 'question'
        session_id: sessionStorage.getItem("session_id"), // Send the session_id generated earlier
      }),
    });

    if (!response.ok) {
      throw new Error("API request failed");
    }

    return await response.json();
  };

  function getIconForOption(option) {
    const icons = {
      Customs: "📄",
      Invoicing: "💰",
      "Email Generation": "✉️",
      "Load Quote": "🚚",
    };
    return icons[option] || "❓";
  }

  // const handleNewChat = async (option) => {
  //   if (
  //     window.confirm(
  //       `Are you sure you want to start a new chat in ${option}? This will clear the current chat history.`
  //     )
  //   ) {
  //     await handleRequestCompleted(false);

  //     setChatHistories((prevHistories) => ({
  //       ...prevHistories,
  //       [option]: [],
  //     }));

  //     if (selectedOption === option) {
  //       addSystemMessage(`How can I assist you with ${option}?`, option);
  //     }
  //   }
  // };

  const confirmNewChat = async () => {
    if (modalOption) {
      await handleRequestCompleted(false);

      setChatHistories((prevHistories) => ({
        ...prevHistories,
        [modalOption]: [],
      }));

      if (selectedOption === modalOption) {
        addSystemMessage(
          `How can I assist you with ${modalOption}?`,
          modalOption
        );
      }

      closeModal(); // Close the modal after confirming
    }
  };

  const handleRequestCompleted = async (displayMessage = true) => {
    const textToSend = "This part is completed";

    // Hide the "Task Completed?" option
    setShowRequestCompletedOption(false);

    // Set loading state
    setIsLoading(true);

    // If displayMessage is true, append user message
    if (displayMessage) {
      // Create user message
      const userMessage = {
        id: generateUniqueId(),
        text: textToSend,
        isUser: true,
      };

      // Append user message with pruning
      setChatHistories((prevHistories) => {
        const updatedMessages = [
          ...prevHistories[selectedOption],
          userMessage,
        ].slice(-MAX_MESSAGES_PER_OPTION);
        return {
          ...prevHistories,
          [selectedOption]: updatedMessages,
        };
      });
    }

    // Call the API
    try {
      const response = await callAzureFunction(textToSend, selectedOption);
      const answer = response.message; // Assuming API returns { message: "..." }

      // Create AI message
      const aiMessage = {
        id: generateUniqueId(),
        text: answer,
        isUser: false,
      };

      // Append AI message with pruning
      setChatHistories((prevHistories) => {
        const updatedMessages = [
          ...prevHistories[selectedOption],
          aiMessage,
        ].slice(-MAX_MESSAGES_PER_OPTION);
        return {
          ...prevHistories,
          [selectedOption]: updatedMessages,
        };
      });
    } catch (err) {
      console.error("Error fetching response from the API:", err);
      const errorMessage = {
        id: generateUniqueId(),
        text: "Error fetching response from the system.",
        isUser: false,
      };
      setChatHistories((prevHistories) => {
        const updatedMessages = [
          ...prevHistories[selectedOption],
          errorMessage,
        ].slice(-MAX_MESSAGES_PER_OPTION);
        return {
          ...prevHistories,
          [selectedOption]: updatedMessages,
        };
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="app">
      <ConfirmationModal
        open={isModalOpen}
        handleClose={closeModal}
        handleConfirm={confirmNewChat}
        option={modalOption}
      />
      {!selectedOption && (
        <div className="welcome-screen">
          <h1 className="welcome-heading">What can I help with?</h1>
          <div className="options-container">
            {Object.keys(apiEndpoints).map((option) => (
              <button
                key={option}
                className="welcome-option-btn"
                onClick={() => handleOptionSelect(option)}
              >
                <span className="button-icon">{getIconForOption(option)} </span>
                {option}
              </button>
            ))}
          </div>
        </div>
      )}

      {/* After an option is selected, show the chat UI */}
      {selectedOption && (
        <div className="chat">
          <div className="chat-options">
            <header className="chat-header">
              {Object.keys(apiEndpoints).map((option) => (
                <div key={option} className="option-container">
                  <button
                    className={`option-chat-btn ${
                      selectedOption === option ? "active" : ""
                    }`}
                    onClick={() => handleOptionSelect(option)}
                  >
                    <span className="button-icon">
                      {getIconForOption(option)}{" "}
                    </span>
                    {option}
                  </button>
                  {selectedOption === option && (
                    <button
                      className="new-chat-btn"
                      onClick={() => openModal(option)}
                      title={`Start a new chat in ${option}`}
                      aria-label={`Start a new chat in ${option}`}
                    >
                      <MdReplay />
                    </button>
                  )}
                </div>
              ))}
            </header>
          </div>
          <div className="chat-window">
            <div className="chat-body" ref={chatBodyRef}>
              {chatHistories[selectedOption].map((message) => (
                <div
                  key={message.id}
                  className={`chat-message ${
                    message.isUser ? "user-message" : "system-message"
                  }`}
                >
                  {message.text}
                </div>
              ))}
              {isLoading && <LoadingIndicator />}
              {showRequestCompletedOption && (
                <div className="chat-message request-completed-option">
                  <span style={{ marginRight: "10px" }}>Task Completed ?</span>
                  <button
                    className="complete-button"
                    onClick={handleRequestCompleted}
                  >
                    <BsCheckLg />{" "}
                  </button>
                </div>
              )}
            </div>
            <div className="chat-footer">
              <button className="file-upload-btn" title="Upload Files">
                📌
              </button>

              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleSend()}
                placeholder="Type a message..."
              />
              <button
                className="send-btn"
                title="Send Message"
                onClick={handleSend}
                aria-label="Send Message"
                disabled={isLoading} // Disable send button while loading
              >
                ➤
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
